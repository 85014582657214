import { ICallbackError } from '@dto'

let instance = {} as Settings

export default class Settings {
  public callbacksError: ICallbackError
  constructor(
    public theme: string,
    public domain: string,
    private env: string,
    public useFingerPrint: boolean = false,
    public pathsFingerPrint: string[] = []
  ) {
    this.theme = theme
    this.domain = domain
    this.env = env
    instance = this
    this.useFingerPrint = useFingerPrint
    this.pathsFingerPrint = ['connect/token'].concat(pathsFingerPrint)
  }

  static getInstance() {
    if (instance) {
      return instance
    }
  }

  static setup(
    theme: string,
    domain: string,
    env: string,
    useFingerPrint: boolean = false,
    pathsFingerPrint: string[] = []
  ) {
    return new Settings(theme, domain, env, useFingerPrint, pathsFingerPrint)
  }

  setCallbackError(callbaks: ICallbackError) {
    if (!instance) {
      throw new Error('Instance empty')
    }

    instance.callbacksError = callbaks
  }

  get urlAuth() {
    let url
    switch (this.env) {
      case 'local':
        url = 'https://devapi.mundipagg.com/auth/v2'
        break
      case 'staging':
        url = 'https://stgapi.mundipagg.com/auth/v2'
        break
      case 'production':
        url = 'https://api.mundipagg.com/auth/v2'
        break
      default:
        url = 'https://api.mundipagg.com/auth/v2'
    }
    return url
  }

  get envAcronym() {
    let acronym
    switch (this.env) {
      case 'local':
        acronym = 'local_'
        break
      case 'staging':
        acronym = 'stg_'
        break
      case 'production':
        acronym = ''
        break
      default:
        acronym = ''
    }
    return acronym
  }
}
