import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Analitica from '@/vendor/analitica.vendor'

import Motherboard from '@/views/Motherboard.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'
import { merchantAllowListViewEnabled } from '@/actions-permission/merchant-allowlist.permissions'
import {
  settingsInfoGetEnabled,
  settingsGeneralDataViewEnabled,
  settingsFunctionalitiesViewEnabled
} from '@/actions-permission/settings.permissions'

import { routeAccounts } from './accounts'
import { routeAdmins } from './admins'
import { routeStaff } from './staff'
import { routeMyPlan } from './myPlan' // hide subscription route
import { routeFinance } from './finance'
import { routeHome } from './home'
import { routeCharges } from './charges.route'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: '_active',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/:merchant_id',
      component: Motherboard,
      beforeEnter: async (to, from, next) => {
        if (store.getters.maintenance) {
          return next({ name: 'maintenance' })
        }

        if (store.getters.userProfile) {
          return next()
        }

        // eslint-disable-next-line
        const { merchant_id } = to.params
        if (!merchant_id.includes('merch_')) {
          return next({ name: 'default' })
        }

        store.commit('toggleGlobalLoading', true)

        try {
          await store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
          await store.dispatch('GET_AUTHORIZATION', { merchant_id })

          Analitica.updateMetadata({
            user: {
              merchant_id: store.getters.merchantId,
              account_id: undefined,
              user_email: store.getters.profile?.user?.email
            }
          })

          store.commit('toggleGlobalLoading', false)

          next()
        } catch (_) {
          next(false)
        }
      },
      children: [
        {
          path: '',
          name: 'motherboard',
          redirect: { name: 'insights' }
        },
        routeHome,
        routeAccounts,
        routeCharges,
        routeAdmins,
        routeStaff,
        routeMyPlan,
        routeFinance,
        {
          path: 'myplan/subscribe/',
          name: 'myPlanSubscribe',
          beforeEnter: (to, from, next) => {
            if (!store.getters.permissionIsEnabled('merchant_plan:view')) {
              return store.commit('toggleGlobalError', {
                status: true,
                code: 404
              })
            }
            next()
          },
          component: () =>
            import(
              /* webpackChunkName: "subscribe" */ '@/views/MyPlan/Subscribe.vue'
            )
        },
        {
          path: 'configuration/',
          name: 'configuration',
          beforeEnter: (to, from, next) => {
            const settingsConditions = [
              settingsInfoGetEnabled,
              settingsGeneralDataViewEnabled,
              settingsFunctionalitiesViewEnabled,
              merchantAllowListViewEnabled
            ]

            const hasPermission = settingsConditions.some(condition =>
              condition(store.getters.permissionIsEnabled)
            )

            if (!hasPermission) {
              return store.commit('toggleGlobalError', {
                status: true,
                code: 404
              })
            }

            next()
          },
          component: () =>
            import(
              /* webpackChunkName: "configuration" */ '@/views/Configuration/Configuration.vue'
            )
        }
      ]
    },
    {
      path: '/',
      name: 'default',
      component: Motherboard,
      beforeEnter: async (to, from, next) => {
        if (store.getters.maintenance) {
          return next({ name: 'maintenance' })
        }

        if (store.getters.userProfile) {
          return next()
        }

        try {
          const response = await store.dispatch('GET_AUTHORIZATION')

          if (!response) {
            return store.commit('toggleGlobalError', {
              status: true,
              code: 401
            })
          }

          const merchantActiveId = response.id
          if (!merchantActiveId) {
            return store.commit('toggleGlobalError', {
              status: true,
              code: 404
            })
          }

          store.commit('toggleGlobalLoading', true)
          store.dispatch('GET_MERCHANT_SUMMARY', merchantActiveId).then(() => {
            store.commit('toggleGlobalLoading', false)
            next({ path: `/${merchantActiveId}/accounts/` })
          })
        } catch (_) {
          next(false)
        }
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          store.commit('toggleGlobalLoading', false)
          next()
        } else {
          next({ name: 'default' })
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'default' }
    }
  ]
})
