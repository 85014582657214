<template>
  <div
    v-if="!isIE"
    id="app"
    class="jade pagarme"
  >
    <delinquent v-if="blockAccess" />
    <global-loader v-show="isLoading && !hasError && !blockAccess" />
    <global-error
      v-if="!isLoading && hasError && !blockAccess"
      :showLogo="componentIsEnabled('globalError', 'logo')"
      :code="errorCode"
      :theme="theme"
    />
    <template v-else-if="!isLoading && !hasError && !blockAccess">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GlobalError } from 'emerald-vue/src/components'
import { setDateLocale } from '@/filters'
import { applyThemeOnBody } from '@/support/toggleThemeMode'
import GlobalLoader from './components/project/globals/GlobalLoader'
import Delinquent from './components/project/globals/Delinquent'

export default {
  name: 'App',
  components: {
    GlobalLoader,
    GlobalError,
    Delinquent
  },
  data () {
    return {
      delinquentAllowedRoutes: ['invoices', 'invoices-detail'],
      theme: process.env.VUE_APP_THEME
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'globalLoading',
      hasError: 'globalError',
      appVersion: 'appVersion',
      merchantDelinquent: 'merchantDelinquent',
      merchantActive: 'merchantActive',
      isEmployee: 'isEmployee',
      pref: 'pref'
    }),
    isIE () {
      return window.isIE
    },
    blockAccess () {
      return (
        this.merchantDelinquent &&
        this.$route.name &&
        !this.delinquentAllowedRoutes.includes(this.$route.name) &&
        !this.isEmployee
      )
    },
    errorCode () {
      return this.$store.state.globalErrorCode
    }
  },
  watch: {
    $route (to, from) {
      const routeTitle = `meta.pageTitle.${to.name}`
      this.$nextTick().then(() => {
        document.title = `${this.merchantActive.name} | ${this.$t(
          routeTitle
        )} - ${process.env.VUE_APP_TITLE}`
      })
    }
  },
  beforeMount () {
    applyThemeOnBody()
    this.$i18n.locale = this.pref.language
    this.setLanguage()
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  methods: {
    ...mapActions({
      setPreferemces: 'SET_PREFERENCES'
    }),
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    setLanguage () {
      const preferences = this.pref
      preferences.language = this.$i18n.locale
      setDateLocale(this.$i18n.locale)
      this.setPreferemces(preferences)
    }
  }
}
</script>

<style>
/* show underline on links inside tables */
tr:hover .emd-link {
  text-decoration: underline;
}

#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 300ms;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
