export type TFilter = 'acquirer' | 'network' | 'bank' | 'classification' | 'installment'

export enum EFilter {
    acquirer = 1,
    network = 1,
    classification = 1,
    installment = 1
}

export enum EFilterPayout {
    acquirer = 1,
    network = 2,
    bank = 3
}
