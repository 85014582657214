import { Read } from './read'
import { ModuleDefault } from '../default'
import { ISetUp, IPageParams,charges } from '@dto'
import { Create } from './create'

export class ChargesModule extends ModuleDefault {
    private _read: Read
    private _create: Create


    // Read
    public getAll: (merchantId: string, params?: IPageParams) => Promise<any>
    public getById: (merchantId: string, chargeId: string) => any

    // Create
    public postFileExport: (merchantId: string, body: charges.IExportFileBody) => Promise<any>

    constructor(setup: ISetUp) {
        super(setup)
        this._read = new Read(setup)
        this._create = new Create(setup)

        this.getAll = this._read.getAll
        this.getById = this._read.getById
        this.postFileExport = this._create.postFileExport
    }
}
