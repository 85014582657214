<template>
  <div class="el-core__header__right">
    <div class="emd-drawer _right">
      <div
        class="emd-drawer__trigger"
        :class="{ _open: isOpen }"
      >
        <button
          class="emd-action-button"
          @click="toggleDrawerMenu"
        >
          <i
            class="emd-action-button__icon uil uil-ellipsis-v"
            transform="width-6"
          ></i>
        </button>
      </div>

      <div
        class="emd-drawer__content"
        :class="{ _open: isOpen }"
      >
        <div
          class="emd-application"
          v-if="canShowAppsList"
        >
          <emd-dropdown>
            <template v-slot:trigger>
              <div class="emd-application__content">
                <!-- ícone de novidade -->
                <span
                  class="emd-status-tag _color_success"
                  v-if="canShowStatusTag"
                >
                  <emd-status-icon />
                </span>
                <i
                  class="emd-application__icon uil uil-apps"
                  fixed-width
                  transform="width-16"
                ></i>
                <span class="emd-text emd-text _size_section"> Company </span>
                <i
                  class="emd-application__arrow uil uil-angle-down"
                  transform="width-14"
                ></i>
              </div>
            </template>
            <template v-slot:content>
              <template v-if="canShowAppsList">
                <div
                  class="emd-text _color_default _type_label _size_tiny eu-ma_2"
                >
                  {{ $t('apps-menu.title') }}
                </div>
                <ul>
                  <li
                    class="emd-dropdown__content__item"
                    v-if="canShowStatistics"
                  >
                    <a
                      class="emd-text _color_default"
                      :href="urlFinance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>
                        {{ $t('apps-menu.statistics') }}
                      </p>
                    </a>
                  </li>
                </ul>
              </template>
            </template>
          </emd-dropdown>
        </div>
        <div
          class="emd-dropdown _position_right jsDropdown"
          data-onboarding="profile"
          id="userDropdown"
        >
          <emd-dropdown :isOpen="profileOpen">
            <template v-slot:trigger>
              <span>
                <emd-tippy
                  id="profile"
                  tag="button"
                  class="emd-action-button _has-tooltip"
                  :type="'action-button'"
                  :content="$t('profile')"
                >
                  <i
                    class="emd-action-button__icon uil uil-user"
                    fixed-width
                    transform="width-16 left-2"
                  ></i>
                  <i
                    class="emd-action-button__arrow uil uil-angle-down"
                    transform="width-14"
                  ></i>
                </emd-tippy>
              </span>
            </template>
            <template v-slot:content>
              <div class="emd-dropdown__content__item">
                <span
                  id="userPermission"
                  class="emd-badge eu-mt_1"
                >
                  {{ permission }}
                </span>
                <div
                  class="emd-text _color_default _type_label _size_tiny eu-ma_2"
                >
                  {{ $t('profile') }}
                </div>
              </div>
              <ul>
                <li class="emd-dropdown__content__item">
                  <a
                    class="emd-text _color_default"
                    :href="profileUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p class="emd-dropdown__content__item__text">
                      <span class="emd-text _color_nonessential">
                        <i
                          class="uil uil-user"
                          fixed-width
                          transform="width-14"
                        ></i>
                      </span>
                      <span id="userName">
                        {{ firstName }} {{ lastName }}
                      </span>
                    </p>
                  </a>
                </li>
                <li class="emd-dropdown__content__item">
                  <a
                    class="emd-text _color_danger"
                    href="javascript:void(0);"
                    @click="triggerLogout"
                  >
                    <p class="emd-dropdown__content__item__text">
                      <i
                        class="uil uil-sign-out-alt"
                        fixed-width
                        transform="width-14"
                      ></i>
                      <span>
                        {{ $t('profile-menu.logout') }}
                      </span>
                    </p>
                  </a>
                </li>
              </ul>
            </template>
          </emd-dropdown>
        </div>
        <span v-if="isEmployee">
          <emd-tippy
            :href="urlBackoffice"
            id="backoffice"
            tag="a"
            :class="[
              'emd-action-button _type_outline _has-tooltip',
              backofficeIconClass
            ]"
            :type="'action-button'"
            :content="$t('backoffice')"
          >
            <span>
              <emd-pagarme-icon />
            </span>
          </emd-tippy>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import EmdTippy from '@/components/emerald/atoms/EmdTippy'
import { EmdPagarmeIcon } from 'emerald-vue/src/components'
import EmdDropdown from '@/components/emerald/molecules/EmdDropdown.vue'
import EmdStatusIcon from '@/components/emerald/atoms/EmdStatusIcon.vue'

export default {
  name: 'AppCoreHeaderRight',
  components: {
    EmdTippy,
    EmdDropdown,
    EmdStatusIcon,
    EmdPagarmeIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      profileOpen: false
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    firstName () {
      return this.profile.first_name
    },
    lastName () {
      return this.profile.last_name
    },
    profileUrl () {
      return `${process.env.VUE_APP_PROFILE_URL}`
    },
    urlBackoffice () {
      return `${process.env.VUE_APP_BACKOFFICE_URL}`
    },
    statisticsAvailability () {
      return this.$store.getters.statisticsAvailability
    },
    canShowStatistics () {
      return (
        this.permissionIsEnabled('finance:view') && this.statisticsAvailability
      )
    },
    canShowAppsList () {
      return this.canShowStatistics
    },
    canShowStatusTag () {
      return false
    },
    permission () {
      return this.$store.getters.userType.value
    },
    urlFinance () {
      return `${process.env.VUE_APP_FINANCE_URL}/${this.merchant.id}/summary`
    },
    backofficeIconClass () {
      if (this.componentIsEnabled('backofficeIcon', 'pagarmeIcon'))
        return 'emd-pagarme-icon-outline'
      return 'emd-mundi-icon'
    }
  },
  methods: {
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    componentIsEnabled (entity, action) {
      return this.$store.getters.componentIsEnabled(entity, action)
    },
    openOnboarding () {
      this.$emit('openOnboarding')
    },
    triggerLogout () {
      this.$emit('userLoggedOut')
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "apps-menu": {
      "statistics": "Finance",
      "title": "Seus apps",
      "get-to-know": "Conheça"
    },
    "company": "Company",
    "profile": "Perfil",
    "backoffice": "Backoffice",
    "profile-menu": {
      "onboarding": "Guia de acesso",
      "logout": "Sair"
    }
  },
  "en-US": {
    "apps-menu": {
      "statistics": "Finance",
      "title": "Your apps",
      "get-to-know": "Get to know"
    },
    "company": "Company",
    "profile": "Profile",
    "backoffice": "Backoffice",
    "profile-menu": {
      "onboarding": "Oboarding",
      "logout": "Logout"
    }
  }
}
</i18n>
