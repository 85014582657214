export * as cookies from './cookie.dto'
export * as merchant from './merchant.dto'
export * as invoices from './invoices.dto'
export * as statistics from './statistics.dto'
export * as charges from './charges.dto'

export type TEnv = 'development' | 'staging' | 'production'
export type TTheme = 'mundipagg' | 'pagarme' | 'jpmorgan'
export type TLanguage = 'pt-BR' | 'en-US'
export type TThemeMode = 'light' | 'dark' | 'default'
export type TGender = 'Other' | 'Male' | 'Female'
export type TRole = 'root' | 'manager' | 'developer' | 'analyst' | 'support' | 'observer' | 'controller' | 'owner' | 'admin'
export type TApplication = 'profile' | 'dash' | 'company' | 'shop' | 'finance' | 'hubdash' | 'onboarding' | 'backoffice'
export type TStatus = 'active' | 'inactive' | 'pending'

export interface IResponse<T> {
  code: number
  notifications?: any
  is_valid: boolean
  data: T
}

type TPagin = {
  [P in 'paging' | 'pagination']: {
    current_page: number;
    page_size: number;
    total_items: number;
    total_page: number;
  };
}

export interface IList<T> extends TPagin {
  items: Array<T>
}

export interface IData { environment: TEnv; theme: TTheme }

export interface ISetUp {
  theme: TTheme
  domain: EDomain
  environment: TEnv
  urlBase: EUrl
  urlDash: EUrlDash
  acronym: EAcronym
}

export enum EUrl {
  production = 'https://api.mundipagg.com/company/v2',
  staging = 'https://stgapi.mundipagg.com/company/v2',
  development = 'https://devapi.mundipagg.com/company/v2'
}

export enum EUrlDash {
  production = 'https://api.mundipagg.com/dashboard/v2',
  staging = 'https://stgapi.mundipagg.com/dashboard/v2',
  development = 'https://devapi.mundipagg.com/dashboard/v2'
}

export enum EDomain {
  mundipagg = '.mundipagg.com',
  pagarme = '.pagar.me',
  jpmorgan = '.mundipagg.com'
}

export enum EAcronym {
  production = '',
  staging = 'stg_',
  development = 'dev_'
}

export interface IAddress {
  street: string
  number: string
  neighborhood: string
  zip_code: string
  city: string
  state: string
  country: string
  complement: string
}

export interface IPageParams {
  page?: number
  size?: number
  page_number?: number
  page_size?: number
  customer_id?: string
  subscription_id?: string
  merchantId?: string
}
