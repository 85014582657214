export * as cookies from './cookie.dto'

export type TEnv = 'development' | 'staging' | 'production'
export type TApplication = 'profile' | 'dash' | 'company' | 'shop' | 'finance' | 'hubdash' | 'onboarding' | 'backoffice'
export type TTheme = 'mundipagg' | 'pagarme' | 'jpmorgan'

export enum EDomain {
  mundipagg = '.mundipagg.com',
  pagarme = '.pagar.me'
}

export enum EUrl {
  production = 'https://api.mundipagg.com',
  staging = 'https://stgapi.mundipagg.com',
  development = 'https://devapi.mundipagg.com'
}

export enum EAcronym {
  production = '',
  staging = 'stg_',
  development = 'dev_'
}

export interface IData {
  environment: TEnv
  theme: TTheme
}

export interface ISetUp {
  theme: TTheme
  domain: EDomain
  environment: TEnv
  urlBase: EUrl
  acronym: EAcronym
}

export interface ICallbackError {
  unauthorized?: () => void
}
