import { Read } from './read'
import { Create } from './create'
import { ModuleDefault } from '../default'
import { ISetUp, IPageParams } from '@dto'

export class AccountModule extends ModuleDefault {
  private _read: Read
  private _create: Create

  // Read
  public getAll: (merchantId: string, params?: IPageParams) => Promise<any>
  public getById: (merchantId: string, accountId: string) => any

  // Create
  public create: (merchantId: string, data: any) => any

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)
    this._create = new Create(setup)

    this.getAll = this._read.getAll
    this.getById = this._read.getById

    this.create = this._create.create
  }
}
