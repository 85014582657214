import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import { sanitizeUrl } from '@braintree/sanitize-url'

import App from './App.vue'
import router from './router'
import store from './store/index'
import i18n from './i18n'
import { dateFormat } from './filters'
import getGoogleAnalyticsSettings from './utils/googleAnalytics.utils'

// FONT AWESOME CONFIG & IMPORTS
import './plugins/click-outside'

// Jade
import './components/jade/css/index.css'
import '@stone-payments/jade-design-tokens/pagarme/theme.css'
// import '@stone-payments/jade-design-tokens/pagarme/fonts.css'

// datepicker
import 'vanillajs-datepicker/css/datepicker.css'

// emerald
import 'emerald-workbench/dist/emerald.pagarme.min.css'

// VUE APP THEME
if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
  // Vue requires the use of a polyfill to correctly handle the shadow dow,
  // which is required to encapsulate styles in the micro frontend architecture
  const shadow = require('vue-shadow-dom')
  Vue.use(shadow)

  // start listening for route changes in order to render micro-frontend bundles
  const { start } = require('single-spa')
  start()
}

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)
}

Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.filter('dateFormat', dateFormat)
Vue.prototype.$sanitize = sanitizeUrl

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
