import Cookies from 'js-cookie'
import { verifyAuth, verifyFp, handleBodyRequest } from '../../commons/read'
import Settings from '../Settings'

export async function requestInterceptor(config) {
  const { envAcronym, theme } = Settings.getInstance()
  const token = Cookies.get(`${envAcronym}mp_tk`)
  const sessionId = sessionStorage.getItem(`${envAcronym}mp_si`)
  const authConfig = verifyAuth(config, token, sessionId)
  const cookiePreferences = Cookies.get(`${envAcronym}mp_pref`)
  const preferenceDefault = { timezone: 'E. South America Standard Time', language: 'pt-BR', appearance: { theme: 'mundipagg', mode: 'light' } }
  const pref = cookiePreferences ? JSON.parse(atob(cookiePreferences)) : preferenceDefault
  const request = await verifyFp(authConfig)

  request.headers['Accept-Language'] = pref.language
  request.headers['X-Theme'] = theme
  request.headers.Timezone = pref.timezone

  return request
}

export function errorRequestInterceptor(error) {
  const body = handleBodyRequest(error)
  return Promise.reject(body)
}

export function requestInterceptorNoHeader(config) {
  return config
}
