import Vue from 'vue'
import Vuex from 'vuex'

import UtilitiesCore from 'utilities-js'
import ScopeCore from 'scope-js'
import DashCore from 'dash-js'
import { CompanyCore } from 'company-ts'
import { ProfileCore } from 'profile-ts'
import IDCore from 'id-js'

import account from '@/store/modules/account'
import commons from '@/store/modules/commons'
import invite from '@/store/modules/invite'
import invoice from '@/store/modules/invoice'
import merchant from '@/store/modules/merchant'
import nps from '@/store/modules/nps'
import profile from '@/store/modules/profile'
import role from '@/store/modules/role'
import subscription from '@/store/modules/subscription'
import user from '@/store/modules/user'
import whitelabel from '@/store/modules/whitelabel'
import home from '@/store/modules/home'
import bestAccounts from '@/store/modules/bestAccounts'
import charges from '@/store/modules/charges.store'

import { version } from '@/../package.json'

const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const DashInstance = new DashCore(dataCore)
export const ScopeInstance = new ScopeCore(dataCore)
export const UtilitiesInstance = new UtilitiesCore(dataCore)
export const ProfileTSInstance = new ProfileCore(dataCore)
export const CompanyTSInstance = new CompanyCore(dataCore)
export const IDCoreInstance = new IDCore(dataCore)

Vue.use(Vuex)

export const env = ProfileTSInstance.acronym

export default new Vuex.Store({
  state: {
    globalLoading: true,
    globalError: false,
    globalErrorCode: '',
    appVersion: version,
    maintenance: false
  },
  mutations: {
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
    }
  },
  actions: {
    TOGGLE_GLOBAL_LOADING ({ commit }, status) {
      commit('toggleGlobalLoading', status)
    }
  },
  getters: {
    globalLoading (state) {
      return state.globalLoading
    },
    globalError (state) {
      return state.globalError
    },
    appVersion (state) {
      return state.appVersion
    },
    maintenance (state) {
      return state.maintenance
    }
  },
  modules: {
    account,
    commons,
    invite,
    invoice,
    merchant,
    nps,
    profile,
    role,
    subscription,
    user,
    whitelabel,
    home,
    bestAccounts,
    charges
  }
})
