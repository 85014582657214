import { Read } from './read'
import { ModuleDefault } from '../default'
import { ISetUp, statistics } from '@dto'

export class StatisticsModule extends ModuleDefault {
  private _read: Read

  // Read
  public getPrepayment: (merchantId: string, params?: {}) => Promise<any>
  public getPayout: (merchantId: any, filter: statistics.TFilter, params?: { filter?: number; }) => Promise<any>
  public getPayoutSummary: (merchantId: string, filter: statistics.TFilter, params?: { filter?: number; }) => Promise<any>
  public getPayoutSchedule: (merchantId: string, params?: {}) => Promise<any>
  public getChargeback: (merchantId: string, params?: {}) => Promise<any>
  public getCancellation: (merchantId: string, params?: {}) => Promise<any>
  public getSalesSummary: (merchantId: string, params?: {}) => Promise<any>
  public getSalesSeries: (merchantId: string, params?: {}) => Promise<any>
  public getSalesDetail: (merchantId: string, filter: statistics.TFilter, params?: { filter?: number; }) => Promise<any>
  public getConciliationAvailability: (merchantId: string) => Promise<any>

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)

    this.getPrepayment = this._read.getPrepayment
    this.getPayout = this._read.getPayout
    this.getPayoutSummary = this._read.getPayoutSummary
    this.getPayoutSchedule = this._read.getPayoutSchedule
    this.getChargeback = this._read.getChargeback
    this.getCancellation = this._read.getCancellation
    this.getSalesSummary = this._read.getSalesSummary
    this.getSalesSeries = this._read.getSalesSeries
    this.getSalesDetail = this._read.getSalesDetail
    this.getConciliationAvailability = this._read.getConciliationAvailability
  }
}
