import { statistics } from '@dto'
import { ModuleDefault } from '../default'

export class Read extends ModuleDefault {
  // TODO: Adicionar interfaces de retorno
  public getPrepayment(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/prepayment${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getPayout(merchantId: any, filter: statistics.TFilter, params: { filter?: number; } = {}): Promise<any> {
    params.filter = statistics.EFilterPayout[filter]

    if (!params.filter) {
      throw new Error(`getPayout requires a valid filter parameter. You have entered the value ${filter}`)
    }

    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/payout${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getPayoutSummary(merchantId: string, filter: statistics.TFilter, params: { filter?: number; } = {}): Promise<any> {
    params.filter = statistics.EFilterPayout[filter]

    if (!params.filter) {
      throw new Error(`getPayout requires a valid filter parameter. You have entered the value ${filter}`)
    }

    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/payout/summary${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getPayoutSchedule(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/payout/schedule${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getChargeback(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/chargeback${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getCancellation(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/cancellation${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getSalesSummary(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/sales/summary${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getSalesSeries(merchantId: string, params = {}): Promise<any> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/sales/series${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getSalesDetail(merchantId: string, filter: statistics.TFilter, params: { filter?: number; } = {}): Promise<any> {
    params.filter = statistics.EFilter[filter]
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/sales/detail${queryParams}`)
  }

  // TODO: Adicionar interfaces de retorno
  public getConciliationAvailability(merchantId: string): Promise<any> {
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/statistics/verify`)
  }
}
