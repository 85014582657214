import { CompanyTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import { isStaffType } from '../../enums/merchant-type.enum'

export const state = () => ({
  isLoading: true,
  data: null
})

export const getters = {
  IS_CHARGES_LOADING (state) {
    return state.isLoading
  },
  CHARGES_DATA (state) {
    return state.data
  }
}

export const mutations = {
  SET_LOADING_CHARGES (state, payload) {
    state.isLoading = payload
  },
  SET_CHARGES_DATA (state, payload) {
    state.data = payload
  }
}

export const actions = {
  GET_CHARGES_DATA ({ commit, dispatch, getters }, { merchantId, ...params }) {
    commit('SET_LOADING_CHARGES', true)

    if (!params.query.size) params.query.size = 30

    const merchatnType = getters.merchant.type.key

    let query = {}

    if (isStaffType(merchatnType)) {
      query = {
        account_id: getters.accountList.items.map(item => item.id).join(',')
      }
    }

    if (!params.query.period_type) {
      params.query.date_filters = 'SevenDays'
      params.query.period_type = 'date_filters'
    }

    query = {
      ...params.query,
      ...query
    }

    return CompanyTSInstance.charges
      .getAll(merchantId, query)
      .then(res => {
        commit('SET_CHARGES_DATA', res)
      })
      .catch(err => catchError(err, { commit, dispatch }))
      .finally(() => commit('SET_LOADING_CHARGES', false))
  },
  POST_EXPORT_REPORT ({ _, getters }, { params = {} }) {
    return CompanyTSInstance.charges
      .postFileExport(getters.merchantId, { ...params })
      .then(res => res)
      .catch(err => Promise.reject(err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
