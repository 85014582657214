import { IPageParams } from '@dto'
import { ModuleDefault } from '../default'

export class Read extends ModuleDefault {
    public getAll(merchantId: string, params: IPageParams = {}): Promise<void> {
        params.page_number = params.page_number || 1
        params.page_size = params.page_size || 10
        const queryParams = this.utils.paramSerializer(params)
        return this.request.get(`${this.setup.urlBase}/merchants/${merchantId}/subscriptions${queryParams}`)
    }

    public getById(merchantId: string, subscriptionId: string): Promise<void> {
        return this.request.get(`${this.setup.urlBase}/merchants/${merchantId}/subscriptions/${subscriptionId}`)
    }
}
