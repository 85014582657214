import { AxiosResponse } from 'axios'
import { handleBaseResponse, handleAutoRefresh, handleCallbacksError } from '../../commons/read'

function getData(response: any) {
  const values = Object.keys(response)
  return values.includes('data')
    ? getData(response['data'])
    : response
}

export function responseInterceptor(response: AxiosResponse) {
  const firstRequestResponseCallback = () => {
    const result = getData(response)
    return result
  }
  return handleAutoRefresh().then(firstRequestResponseCallback).catch(firstRequestResponseCallback)
}

export async function errorResponseInterceptor(error) {
  await handleCallbacksError(error)

  const baseResponse = handleBaseResponse(error)
  return handleAutoRefresh()
    .then(() => Promise.reject(baseResponse))
    .catch(() => Promise.reject(baseResponse))
}

export function responseInterceptorToken(response) {
  return response.data || response
}

export async function errorResponseInterceptorToken(error) {
  await handleCallbacksError(error)
  const baseResponse = handleBaseResponse(error)
  return Promise.reject(baseResponse)
}
