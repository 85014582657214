import store from '@/store'
import chargeViewEnabled from '@/actions-permission/charges.permissions'

const routeCharges = {
  path: 'charges',
  name: 'charges',
  component: () =>
    import(/* webpackChunkName: "charges" */ '@/views/Charges/index.vue'),
  beforeEnter: (to, from, next) => {
    const { merchant_id: merchantId } = to.params

    if (!chargeViewEnabled(store.getters.permissionIsEnabled)) {
      store.commit('toggleGlobalError', { status: true, code: 404 })
    }

    const query = {
      sort_mode: 'desc',
      ...to?.query
    }

    const params = {
      merchantId,
      query
    }
    store.dispatch('GET_ACCOUNTS', params).then(() => {
      store.dispatch('GET_CHARGES_DATA', params)
      next()
    })
  }
}

export { routeCharges }
