import UtilsService from '@services/utils'
import CookieService from '@services/cookies'
import SessionService from '@services/session'
import LocalService from '@services/local'
import { IRequest, Settings, request } from 'support-lib'
import { ISetUp } from '@dto'

export class ModuleDefault {
  protected setup: ISetUp
  protected cookie: CookieService
  protected request: IRequest
  protected utils: UtilsService
  protected session: SessionService
  protected local: LocalService

  constructor(setup: ISetUp) {
    this.setup = setup
    this.request = request
    this.cookie = new CookieService(setup)
    this.session = new SessionService(setup)
    this.local = new LocalService(setup)
    this.utils = new UtilsService()
    Settings.setup(setup.theme, setup.domain, setup.environment)
  }
}
