import { ModuleDefault } from '../default'
import { charges } from "@dto"

export class Create extends ModuleDefault {
  public postFileExport(merchantId: string, body: charges.IExportFileBody) {
    return this.request.post(
      `${this.setup.urlBase}/merchants/${merchantId}/charges/file-export`,
      body
    )
  }
}
