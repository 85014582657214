import { everyActionsPermissionIsEnabled } from '.'
import { isStaffType, MERCHANT_TYPES } from '@/enums/merchant-type.enum'

export const MERCHANTSTAFF_ACTIONS_ENUM = {
  view: 'merchant_staff:view',
  search: 'merchant_staff:search',
  edit: 'merchant_staff:edit',
  get: 'merchant_staff:get'
}

const MERCHANTSTAFF_ACTIONS = Object.values(MERCHANTSTAFF_ACTIONS_ENUM)

const merchantStaffEnabled = permissionIsEnabledFn =>
  everyActionsPermissionIsEnabled(MERCHANTSTAFF_ACTIONS, permissionIsEnabledFn)

const merchantStaffViewEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTSTAFF_ACTIONS_ENUM.view)

const merchantStaffGetEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTSTAFF_ACTIONS_ENUM.get)

const merchantStaffGetEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantStaffGetEnabled(permissionIsEnabledFn) && !isStaffType(merchantType)

const merchantStaffSearchEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTSTAFF_ACTIONS_ENUM.search)

const merchantStaffSearchEnabledAndIsNotStaff = (
  permissionIsEnabledFn,
  { key: merchantType } = MERCHANT_TYPES.staff
) =>
  merchantStaffSearchEnabled(permissionIsEnabledFn) &&
  !isStaffType(merchantType)

const merchantStaffEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(MERCHANTSTAFF_ACTIONS_ENUM.edit)

export {
  merchantStaffGetEnabled,
  merchantStaffViewEnabled,
  merchantStaffSearchEnabled,
  merchantStaffEditEnabled,
  merchantStaffGetEnabledAndIsNotStaff,
  merchantStaffSearchEnabledAndIsNotStaff
}

export default merchantStaffEnabled
