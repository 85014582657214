class UtilsService {
    public paramSerializer(params: { [key: string]: any } = {}) {

        const queryParams = params ? Object.entries(params) : []

        let queryString = ''

        if (queryParams.length > 0) {
            queryString = '?' + queryParams.map(([param, valueOfParam]) => {
                if (Array.isArray(valueOfParam)) {
                    return valueOfParam.map(itemOnValueOfParam => `${param}=${itemOnValueOfParam}`).join('&')
                } else {
                    return new URLSearchParams({ [param]: valueOfParam }).toString()
                }
            }).join('&')
        }

        return queryString
    }

    public paramSerializerURL(params = {}): string {
        let queryParams: any = Object.entries(params)
        if (queryParams.length > 0) {
            queryParams = queryParams.reduce((prev, actual, index) => {
                if (actual[0] === 'merchant_id' || actual[0] === 'merchantId') { actual[0] = 'merchants' }
                if (actual[0] === 'account_id' || actual[0] === 'accountId') { actual[0] = 'accounts' }
                return index === 0 ? prev + `${actual[0]}/${actual[1]}` : prev + `/${actual[0]}/${actual[1]}`
            }, '/')
        }
        return queryParams as string
    }

    public clean(obj: object): object {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName]
            }
        }
        return obj
    }
}

export default UtilsService
