import { IList, IPageParams, invoices } from '@dto'
import { ModuleDefault } from '../default'

export class Read extends ModuleDefault {
  public getAll(merchantId: string, params: IPageParams = {}): Promise<IList<invoices.IGetAll>> {
    params.page_number = params.page_number || 1
    params.page_size = params.page_size || 10
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlBase}/merchants/${merchantId}/invoices${queryParams}`)
  }

  public getById(merchantId: string, invoiceId: string): Promise<invoices.IGetById> {
    return this.request.get(`${this.setup.urlBase}/merchants/${merchantId}/invoices/${invoiceId}`)
  }
}
