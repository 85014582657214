import { merchant } from '@dto'
import { ModuleDefault } from '../default'

export class Update extends ModuleDefault {
  public update(merchantId: string, body: merchant.IUpdateUpdate): Promise<merchant.IGeneralData> {
    return this.request.patch(`${this.setup.urlBase}/merchants/${merchantId}`, body)
  }

  // TODO: Adicionar interfaces
  public updateSettings(merchantId: string, body: any): Promise<any> {
    return this.request.put(`${this.setup.urlBase}/merchants/${merchantId}/settings`, body)
  }

  public updateAllowedIps(merchantId: string, body: merchant.IAllowedIps): Promise<merchant.IAllowedIps> {
    return this.request.patch<merchant.IAllowedIps>(`${this.setup.urlBase}/merchants/${merchantId}/allowed-ips`, body)
  }
}
