<template>
  <div class="emd-onboarding__greeting eu-mb_4">
    <div class="emd-container _footer_centered _footer_end_md">
      <div
        class="emd-container__header eu-height_auto eu-justify_end eu-align-items_start"
      >
        <a
          href="javascript:void(0)"
          class="emd-btn _color_contrast _type_link"
          @click="close"
        >
          <i class="uil uil-times"></i>
        </a>
      </div>
      <div class="emd-container__content">
        <div class="emd-onboarding__image"></div>
        <h2 class="emd-text _size_page">
          {{ $t('hello') }}
        </h2>
        <p class="emd-text eu-my_2">{{ $t('messageOne') }}</p>
        <i18n
          path="messageTwo"
          tag="p"
          class="emd-text _color_primary eu-my_2"
        >
          <router-link
            :to="{ name: 'accountProduction' }"
            class="emd-link _color_primary"
            >{{ $t('accountTab') }}</router-link
          >
        </i18n>
      </div>
      <div class="emd-container__footer">
        <button
          class="emd-btn _color_tertiary"
          @click="close"
        >
          {{ $t('button') }}
        </button>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "hello": "Boas vindas a Pagar.me!",
    "messageOne": "Essa é a página da sua empresa. Aqui visualiza as informações de todas as suas lojas.",
    "messageTwo": "Para acessar suas lojas, acesse a {0}.",
    "accountTab": "aba contas",
    "button": "ok, entendi"
  },
  "en-US": {
    "hello": "Welcome to Pagar.me!",
    "messageOne": "This is your company's page. Here you can view information about all your stores.",
    "messageTwo": "To access your stores, go to the {0}.",
    "accountTab": "accounts tab",
    "button": "ok, got it"
  }
}
</i18n>

<script>
export default {
  name: 'Greeting',
  computed: {
    themeMode () {
      return this.$store.getters.pref.appearance.mode === 'dark'
        ? 'dark'
        : 'light'
    },
    appTheme () {
      return this.$store.getters.whitelabel.theme
    },
    folder () {
      return this.componentIsEnabled('footer', 'switchThemeMode')
        ? this.themeMode
        : this.appTheme
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>

<style scoped>
.emd-container__content > p a {
  text-decoration: none;
}
</style>
