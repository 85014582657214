import { Read } from './read'
import { ModuleDefault } from '../default'
import { IPageParams, ISetUp } from '@dto'
import { invoices } from '@dto'

export class InvoiceModule extends ModuleDefault {
  private _read: Read

  // Read
  public getAll: (merchantId: string, params?: IPageParams) => Promise<any>
  public getById: (merchantId: string, invoiceId: string) => Promise<invoices.IGetById>

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)

    this.getAll = this._read.getAll
    this.getById = this._read.getById
  }
}
