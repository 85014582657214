import { isEmptyObject } from '@/support/helpers.js'
import store from '@/store'
import {
  merchantAdminSearchEnabledAndIsNotStaff,
  merchantAdminGetEnabledAndIsNotStaff,
  merchantAdminInvitesGetEnabledAndIsNotStaff,
  merchantAdminInvitesCreateEnabledAndIsNotStaff
} from '@/actions-permission/merchant-admin.permissions'

const routeAdmins = {
  path: 'admin/',
  component: () =>
    import(/* webpackChunkName: "accessList" */ '@/views/Users/AccessList.vue'),
  props: {
    tabbarItems: [
      {
        route: 'adminUser'
      },
      {
        route: 'adminInvite'
      }
    ]
  },
  children: [
    {
      path: '',
      redirect: { name: 'adminUser' }
    },
    {
      path: 'user/',
      name: 'adminUser',
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.merchantId = store.getters.merchantActive.id

        if (
          !merchantAdminSearchEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_ADMIN_USERS', query)
        next()
      },
      props: {
        actionName: 'GET_ADMIN_USERS',
        getterName: 'adminUsers',
        inviteRoute: 'adminInviteCreate'
      },
      component: () =>
        import(/* webpackChunkName: "userList" */ '@/views/Users/UserList.vue')
    },
    {
      path: 'user/:id',
      name: 'adminUserDetail',
      beforeEnter: (to, from, next) => {
        const userId = to.params.id
        const merchantId = store.getters.merchantActive.id

        if (
          !merchantAdminGetEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_USER_ADMIN', { merchantId, userId })
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "userDetail" */ '@/views/Team/Users/UserDetail.vue'
        )
    },
    {
      path: 'invite/',
      name: 'adminInvite',
      beforeEnter: (to, from, next) => {
        const query = isEmptyObject(to.query) ? { page: 1 } : to.query
        query.merchantId = store.getters.merchantActive.id

        if (
          !merchantAdminInvitesGetEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_ADMIN_INVITES', query)
        next()
      },
      props: {
        actionName: 'GET_ADMIN_INVITES',
        getterName: 'adminInvites',
        inviteRoute: 'adminInviteCreate'
      },
      component: () =>
        import(
          /* webpackChunkName: "InviteList" */ '@/views/Users/Invites/InviteList.vue'
        )
    },
    {
      path: 'invite/create',
      name: 'adminInviteCreate',
      component: () =>
        import(
          /* webpackChunkName: "inviteCreate" */ '@/views/Users/Invites/InviteAdminCreate.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (
          !merchantAdminInvitesCreateEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        next()
      }
    },
    {
      path: 'invite/:id',
      name: 'adminInviteDetail',
      beforeEnter: (to, from, next) => {
        const params = {
          query: {
            merchantId: store.getters.merchantActive.id,
            inviteId: to.params.id
          }
        }

        if (
          !merchantAdminInvitesGetEnabledAndIsNotStaff(
            store.getters.permissionIsEnabled,
            store.getters.merchant.type
          )
        ) {
          return store.commit('toggleGlobalError', { status: true, code: 404 })
        }

        store.dispatch('GET_INVITE_ADMIN', params)
        next()
      },
      component: () =>
        import(
          /* webpackChunkName: "InviteDetail" */ '@/views/Users/Invites/InviteDetail.vue'
        )
    }
  ]
}
export { routeAdmins }
