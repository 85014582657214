import { EAcronym, EDomain, EUrl, EUrlDash, IData, ISetUp, TEnv, TTheme } from '@dto'
import { AccountModule } from '@modules/account'
import { ChargesModule } from '@modules/charges'
import { MerchantModule } from '@modules/merchant'
import { CustomerModule } from '@modules/customer'
import { InvoiceModule } from '@modules/invoice'
import { StatisticsModule } from '@modules/statistics'
import { SubscriptionModule } from '@modules/subscription'

export class CompanyCore {
  private _env: TEnv
  private _theme: TTheme

  public charges: ChargesModule
  public account: AccountModule
  public merchant: MerchantModule
  public customer: CustomerModule
  public invoice: InvoiceModule
  public statistics: StatisticsModule
  public subscription: SubscriptionModule

  constructor(data: IData) {
    this._env = data.environment
    this._theme = data.theme

    this.account = new AccountModule(this.setup)
    this.charges = new ChargesModule(this.setup)
    this.merchant = new MerchantModule(this.setup)
    this.customer = new CustomerModule(this.setup)
    this.invoice = new InvoiceModule(this.setup)
    this.statistics = new StatisticsModule(this.setup)
    this.subscription = new SubscriptionModule(this.setup)
  }

  private get setup(): ISetUp {
    return {
      theme: this._theme,
      domain: this.domain,
      environment: this._env,
      urlBase: this.urlBase,
      urlDash: this.urlDash,
      acronym: this.acronym
    }
  }

  public get urlBase(): EUrl {
    return EUrl[this._env]
  }

  public get urlDash(): EUrlDash {
    return EUrlDash[this._env]
  }

  public get domain(): EDomain {
    return EDomain[this._theme]
  }

  public get acronym(): EAcronym {
    return EAcronym[this._env]
  }
}
