import { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import Settings from '../http/Settings'
import { refreshToken } from '../auth/connect/create'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { isUnauthorized } from '@modules/utils/httpStatusCode'
import { deleteTokens } from '@modules/commons/delete'

function getError(error) {
  if (error?.response) { return error.response }
  return error
}

export function verifyAuth(config, token, session?: string) {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else if (session) {
    const sessionObject = JSON.parse(session)
    config.headers.Authorization = sessionObject.sessionId
  }
  return config
}

export async function generateFingerPrint() {
  try {
    const fpPromise = await FingerprintJS.load({
      apiKey: 'ERNHlECbWU0WtnA25mGS',
      endpoint: 'https://bloodhound.pagar.me/3GvhVO0nDoNApNmM/DXmvjPWvR6rda7DY'
    })

    const fp = await fpPromise.get()

    return fp
  } catch (_err) {
    return
  }
}

export async function verifyFp(config) {
  const instance = Settings.getInstance()

  if (!instance.useFingerPrint) { return config }
  if (!instance.pathsFingerPrint.some(path => config.url.includes(path))) { return config }

  const fp = await generateFingerPrint()

  const { visitorId, requestId } = { ...fp }

  config.headers.visitorid = visitorId || config.headers.visitorid
  config.headers.requestid = requestId || config.headers.requestid

  return config
}

export function handleBaseResponse(error) {
  const err = getError(error)
  return {
    type: 'response',
    status: err ? err.status : null,
    message: error.message,
    stack: error.stack,
    body: err.data || err.body || null
  }
}

export function handleBodyRequest(error) {
  return {
    type: 'request',
    status: error.request.status,
    message: error.message,
    stack: error.stack
  }
}

export function clientSettings(rt) {
  if (rt.type === 'refresh_token') {
    return {
      grant_type: 'refresh_token',
      refresh_token: rt.refresh_token,
      client_id: 'Mundipagg_Id_WebApp',
      client_secret: 'csct_eaLpqVghWUEQrmd2'
    }
  }
  return {
    grant_type: 'refresh_token',
    refresh_token: rt.refresh_token,
    client_id: 'Backoffice_Id_WebApp',
    client_secret: 'csct_X7834RLi2nun2N6Q'
  }
}

export function handleAutoRefresh(): Promise<AxiosRequestConfig | void> {
  const { envAcronym } = Settings.getInstance()
  const autoRefresh = Cookies.get(`${envAcronym}mp_et`) === undefined
  const hasToken = Cookies.get(`${envAcronym}mp_tk`) !== undefined
  const hasRefreshToken = Cookies.get(`${envAcronym}mp_rt`) !== undefined
  if (!hasToken || !hasRefreshToken) {
    const status = 401
    return Promise.reject({
      status,
      response: { status }
    })
  }

  const isRefreshing = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(`${envAcronym}mp_refreshing`)) : false
  if (autoRefresh && hasToken && !isRefreshing) {
    localStorage.setItem(`${envAcronym}mp_refreshing`, 'true')
    return refreshToken().finally(() => {
      localStorage.setItem(`${envAcronym}mp_refreshing`, 'false')
    })
  }
  return Promise.resolve()
}

const isFunction = (callback) => callback && typeof callback === 'function'

export function handleCallbacksError(error) {
  const response = error?.response
  const status = response?.status
  const statusCode = status ?? null

  const { callbacksError } = Settings.getInstance()

  if (isUnauthorized(statusCode)) {
    deleteTokens()

    if (isFunction(callbacksError?.unauthorized)) {
      callbacksError.unauthorized()
    }
  }

  return Promise.resolve(error)
}
