import { IPageParams, merchant, TApplication } from '@dto'
import { ModuleDefault } from '../default'

export class Read extends ModuleDefault {
  // TODO: Atualizar interfaces de retorno
  public getAll(params: IPageParams = {}): Promise<any> {
    params.page = params.page || 1
    params.size = params.size || 9
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants${queryParams}`)
  }

  public getById(merchantId: string): Promise<merchant.IGeneralData> {
    return this.request.get<merchant.IGeneralData>(`${this.setup.urlBase}/merchants/${merchantId}`)
  }

  // TODO: Atualizar interfaces
  public getAMK(merchantId: string, body: any): Promise<any> {
    return this.request.post(`${this.setup.urlDash}/merchants/${merchantId}/amk`, body)
  }

  public async getSummary(merchantId: string, applicationName: TApplication, envVariables: any): Promise<merchant.IGetSummary> {
    const response = await this.request.get<merchant.IGetSummary>(`${this.setup.urlBase}/merchants/${merchantId}/summary`)
    const merchantJPM = envVariables.VUE_APP_MERCHANT_JPM ? envVariables.VUE_APP_MERCHANT_JPM.split(',') : []
    const isJPMWhitelabel = merchantJPM.includes(merchantId)
    const currentUrl = window.location.href
    const theme = (response.is_white_label && isJPMWhitelabel && applicationName !== 'shop') ? 'jpmorgan' : envVariables.VUE_APP_THEME

    const ENV_MAP = {
      dev: 'dev',
      stg: 'stg'
    }

    const env = Object.keys(ENV_MAP).map(key => currentUrl.includes(key) ? key : '').find(i => i) || ''

    const URL_MAP = {
      pagarme: `${applicationName}${this.setup.domain}`,
      mundipagg: `${applicationName}${this.setup.domain}`,
      jpmorgan: `paymentsvcs${this.setup.domain}/${applicationName}`
    }

    response.whitelabel = response.whitelabel || {
      enabled: response.is_white_label,
      theme,
      domain: this.setup.domain,
      url: URL_MAP[theme]
    }

    const urlsDontMatch = currentUrl.indexOf(response.whitelabel.url) === -1
    const localUrl = currentUrl.indexOf('local.mundipagg.com') !== -1

    if (urlsDontMatch && !localUrl && theme === 'jpmorgan') {
      window.location.assign(`https://${env}${response.whitelabel.url}/${merchantId}`)
    }

    return await Promise.resolve(response)
  }

  public getInsights(merchantId: string, params: Record<string, any> = {}): Promise<merchant.IGeneralData> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get<merchant.IGeneralData>(`${this.setup.urlBase}/merchants/${merchantId}/insights${queryParams}`)
  }

  public getBestAccounts(merchantId: string, params: Record<string, any> = {}): Promise<merchant.IGeneralData> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get<merchant.IGeneralData>(`${this.setup.urlBase}/merchants/${merchantId}/bestaccounts${queryParams}`)
  }

  public getAllowedIps(merchantId: string, params: Record<string, any> = {}): Promise<merchant.IAllowedIps> {
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get<merchant.IAllowedIps>(`${this.setup.urlBase}/merchants/${merchantId}/allowed-ips${queryParams}`)
  }
}
